@page {
  size: A4; }

.qrcode-store {
  padding: 20px; }
  .qrcode-store canvas {
    width: 200px !important;
    height: 200px !important; }

@media print {
  .qrcode-store canvas {
    width: 18cm !important;
    height: 18cm !important; } }
