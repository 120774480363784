.confirmUsersPassword .title {
  font-size: 18px;
  text-align: center;
  line-height: 60px;
  color: #000; }

.confirmUsersPassword .showArea {
  width: 100%;
  height: 60px;
  font-size: 26px;
  line-height: 60px;
  font-weight: 600;
  text-align: center;
  background-color: #ECECEC; }

.confirmUsersPassword .input-number-container {
  margin: 0; }
  .confirmUsersPassword .input-number-container li {
    margin: -1px 0 0 -1px;
    border-radius: 0; }
  .confirmUsersPassword .input-number-container .sureBtn {
    border-right-color: #ffa600; }

.confirmUsersPasswordModal .ant-modal-content {
  width: 296px;
  overflow: hidden; }

.confirmUsersPasswordModal div.ant-modal-confirm-content {
  margin: 0; }

.confirmUsersPasswordModal div.ant-modal-body {
  padding: 0; }

.confirmUsersPasswordModal .ant-modal-confirm-btns {
  display: none; }

.confirmUsersPasswordModal .input-number-container {
  transform: translate(-1px, 1px); }
