.header {
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  padding: 0 15px;
  background-color: #fff;
  z-index: 999;
  border-bottom: 1px solid #d2d2d2; }
  .header .user {
    position: relative;
    float: right;
    padding: 0 15px;
    line-height: 69px;
    font-size: 16px;
    cursor: pointer;
    display: none; }
    .header .user i {
      margin: 0 5px; }
    .header .user:hover {
      background: #eee; }
      .header .user:hover .drop-menu {
        display: block; }
    .header .user .drop-menu {
      position: absolute;
      display: none;
      width: 200px;
      right: 0;
      top: 70px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #d2d2d2;
      border-top: none;
      z-index: 1000; }
      .header .user .drop-menu li {
        line-height: 50px; }
        .header .user .drop-menu li:hover {
          color: #0091ff; }
