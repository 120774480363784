.store_pay_setting {
  padding: 0 35px 35px 35px; }
  .store_pay_setting h2 {
    line-height: 2em;
    text-align: center; }
  .store_pay_setting .module-list li {
    margin: 12px 0;
    line-height: 32px; }
  .store_pay_setting h3 {
    line-height: 45px !important;
    border: none !important; }
  .store_pay_setting .key,
  .store_pay_setting .key2 {
    padding: 12px 0; }
    .store_pay_setting .key > input,
    .store_pay_setting .key2 > input {
      width: 256px; }
  .store_pay_setting .key .name {
    margin-right: 8px; }
  .store_pay_setting .key .blod {
    font-weight: 600; }
  .store_pay_setting .key2 .name {
    margin-bottom: 8px; }
  .store_pay_setting .key2 .note {
    margin-top: 8px; }
  .store_pay_setting .note {
    line-height: 3em;
    font-size: 0.8em;
    color: #999; }
  .store_pay_setting .label {
    line-height: 30px;
    text-align: right; }
