.select-account .select-account-content {
  height: calc(100vh - 185px);
  overflow-y: auto; }

.select-account .user-list {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center; }
  .select-account .user-list > div {
    display: inline-block;
    margin: 0 15px;
    margin-top: 15px;
    color: #333;
    cursor: pointer; }
    .select-account .user-list > div img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      border: 3px solid transparent; }
      .select-account .user-list > div img.active {
        border: 3px solid #ff9800; }

.select-account .number-show-container {
  margin-top: 25px;
  text-align: center; }
  .select-account .number-show-container > div {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin: 0 8px;
    border: 2px solid #6da0ff;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    vertical-align: top;
    color: #101010; }

.select-account ul.inputNumber {
  width: 300px;
  margin: 0 auto;
  margin-top: 15px;
  overflow: hidden; }
  .select-account ul.inputNumber li {
    float: left;
    width: 100px;
    height: 80px;
    margin: 0;
    padding: 0;
    line-height: 80px;
    text-align: center;
    font-size: 28px;
    border: 1px solid #dedede;
    border-radius: 5px;
    color: #101010;
    cursor: pointer; }
    .select-account ul.inputNumber li:nth-last-child(1) {
      width: 200px; }
    .select-account ul.inputNumber li:active {
      color: #fff;
      background-color: #ffa600; }
    .select-account ul.inputNumber li img {
      width: 30px;
      height: 30px;
      margin-top: 25px;
      vertical-align: top; }
  .select-account ul.inputNumber .btn-container {
    text-align: center;
    color: #ffa600;
    font-size: 20px; }

.select-account .btns {
  display: flex;
  margin-top: 35px;
  justify-content: space-between; }
  .select-account .btns button {
    width: 180px;
    height: 50px;
    line-height: 50px; }
    .select-account .btns button.change {
      background: #6da0ff;
      color: white;
      border-color: #6da0ff; }
