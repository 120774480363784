.input-number-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 15px;
  overflow: hidden; }
  .input-number-container li {
    float: left;
    width: 100px;
    height: 80px;
    margin: 0;
    padding: 0;
    line-height: 80px;
    text-align: center;
    font-size: 28px;
    border: 1px solid #dedede;
    border-radius: 5px;
    color: #101010;
    cursor: pointer; }
    .input-number-container li:active {
      color: #fff;
      background-color: #ffa600; }
    .input-number-container li img {
      width: 30px;
      height: 30px;
      margin-top: 25px;
      vertical-align: top; }
  .input-number-container .btn-container {
    text-align: center;
    color: #ffa600;
    font-size: 20px; }
  .input-number-container .sureBtn {
    background-color: #ffa600;
    color: #FFF; }
