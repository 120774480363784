.admin-left-menu {
  position: fixed;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000; }
  .admin-left-menu > .ant-menu::-webkit-scrollbar {
    width: 6px;
    background: inherit;
    border-radius: 2px; }
  .admin-left-menu > .ant-menu::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #b7b7b7; }
  .admin-left-menu > .ant-menu {
    overflow-y: auto; }
  .admin-left-menu .ant-menu {
    height: 100%;
    padding-top: 125px;
    background-color: #242c43; }
    .admin-left-menu .ant-menu i {
      margin-right: 10px;
      font-size: 16px; }
    .admin-left-menu .ant-menu .ant-menu-item-selected {
      background-color: #0d1730 !important; }
      .admin-left-menu .ant-menu .ant-menu-item-selected:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #0091ff; }
  .admin-left-menu .logo-container {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 30px;
    text-align: center;
    background-color: #242c43;
    z-index: 99; }
    .admin-left-menu .logo-container img {
      width: 70px; }

@media screen and (max-width: 1400px) {
  .admin-left-menu {
    width: 160px; } }
