ul {
  list-style: none; }

header {
  position: absolute;
  width: 100%;
  height: 160px;
  top: 0;
  left: 0;
  background-color: #6da0ff; }
  header > div {
    width: 10.9%;
    text-align: center; }
  header img {
    margin-top: 50px; }

@media screen and (max-width: 1400px) {
  header > div {
    width: 7%; }
  header img {
    height: 60px;
    margin-top: 80px; } }
