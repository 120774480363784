aside {
  width: 10.9%;
  height: 100%;
  padding-top: 90px;
  background-color: transparent; }
  aside .btn-container {
    height: calc(100% - 100px);
    overflow-y: auto; }
  aside .head-img {
    text-align: center;
    padding: 40px 0;
    padding-bottom: 25px;
    cursor: pointer; }
    aside .head-img img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #b0b0b0; }
  aside .menu {
    width: 100px;
    height: 100px;
    padding: 20px 0;
    margin: 0 auto 15px auto;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 21px 21px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer; }
    aside .menu.active {
      background-color: #ffa600; }
      aside .menu.active img {
        display: none; }
      aside .menu.active img.active {
        display: block; }
      aside .menu.active span {
        color: #fff; }
    aside .menu img {
      display: block;
      width: 32px;
      height: 32px;
      margin: 0 auto; }
      aside .menu img.active {
        display: none; }
    aside .menu .icon-cc-printer {
      display: block;
      font-size: 40px; }
    aside .menu span {
      display: inline-block;
      margin-top: 12px;
      font-size: 16px;
      color: #384045;
      line-height: 16px; }

@media screen and (max-width: 1400px) {
  aside {
    width: 7%; }
    aside .head-img {
      padding-top: 60px;
      padding-bottom: 30px; }
    aside .menu {
      width: 70px;
      height: 70px;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      aside .menu span {
        margin-top: 4px; } }

div.select-account-modal {
  top: 0;
  padding-bottom: 0;
  overflow: hidden; }
  div.select-account-modal .ant-modal-content {
    height: 100vh; }
