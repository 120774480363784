.qrcode {
  display: inline-block;
  padding: 10px; }
  .qrcode .qrcode-title {
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .qrcode .qrcode-title {
    height: 30px; }
    .qrcode .qrcode-title .left {
      text-align: center; }
      .qrcode .qrcode-title .left .top {
        line-height: 15px;
        font-size: 15px; }
      .qrcode .qrcode-title .left .bottom {
        line-height: 12px;
        font-size: 12px; }
    .qrcode .qrcode-title .right {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      border-left: 1px solid #ddd;
      padding-left: 5px; }
  .qrcode .qrcode-img {
    margin-top: 10px; }
    .qrcode .qrcode-img canvas {
      width: 60px !important;
      height: 60px !important; }

@media print {
  html, body {
    overflow: initial; }
  .qrcode-container {
    display: flex;
    flex-wrap: wrap;
    width: 210mm; }
  .qrcode {
    width: 2.95cm;
    height: 4.95cm;
    flex: none; }
    .qrcode .qrcode-title {
      width: 2.6cm;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .qrcode .qrcode-title {
      height: 0.8cm; }
      .qrcode .qrcode-title .left {
        text-align: center; }
        .qrcode .qrcode-title .left .top {
          line-height: 0.5cm;
          font-size: 0.5cm; }
        .qrcode .qrcode-title .left .bottom {
          line-height: 0.3cm;
          font-size: 0.3cm; }
      .qrcode .qrcode-title .right {
        font-size: 0.6cm;
        font-weight: bold;
        text-align: center;
        border-left: 1px solid #ddd;
        padding-left: 0.2cm; }
    .qrcode .qrcode-img {
      margin-top: 0.8cm; }
      .qrcode .qrcode-img canvas {
        width: 2.6cm !important;
        height: 2.6cm !important; } }
