@font-face {
  font-family: "iconfont"; /* Project id 894870 */
  src: url('iconfont.woff2?t=1675931886794') format('woff2'),
       url('iconfont.woff?t=1675931886794') format('woff'),
       url('iconfont.ttf?t=1675931886794') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-24gl-lock2:before {
  content: "\e9d4";
}

.icon-24gl-unlock4:before {
  content: "\e9d6";
}

.icon-suoding:before {
  content: "\e600";
}

.icon-jiesuo:before {
  content: "\e609";
}

.icon-people:before {
  content: "\e736";
}

.icon-jinyonghuiyuan:before {
  content: "\e639";
}

.icon-dianzan1:before {
  content: "\e608";
}

.icon-dianzan:before {
  content: "\e656";
}

.icon-chakan:before {
  content: "\e61c";
}

.icon-chakan1:before {
  content: "\e63e";
}

.icon-zhuyi:before {
  content: "\e61b";
}

.icon-sort:before {
  content: "\e89a";
}

.icon-sort1:before {
  content: "\e688";
}

.icon-drag:before {
  content: "\e8c8";
}

.icon-Drag:before {
  content: "\e61a";
}

.icon-youjian2:before {
  content: "\e642";
}

.icon-youjian:before {
  content: "\e619";
}

.icon-fapiao:before {
  content: "\e64c";
}

.icon-fapiao1:before {
  content: "\e908";
}

.icon-excel:before {
  content: "\e611";
}

.icon-folder:before {
  content: "\eb6b";
}

.icon-Emoji-1:before {
  content: "\e672";
}

.icon-shengyin-guan:before {
  content: "\e6bf";
}

.icon-shengyin-kai:before {
  content: "\e6c0";
}

.icon-kefu:before {
  content: "\e6be";
}

.icon-icon_kefu:before {
  content: "\e65a";
}

.icon-zu125:before {
  content: "\e631";
}

.icon-zu126:before {
  content: "\e632";
}

.icon-icon_3_n:before {
  content: "\e635";
}

.icon-icon_5_n:before {
  content: "\e636";
}

.icon-icon_4_n:before {
  content: "\e637";
}

.icon-icon_1_s:before {
  content: "\e638";
}

.icon-orderlist:before {
  content: "\e6d3";
}

.icon-yonhu:before {
  content: "\e657";
}

.icon-4:before {
  content: "\e607";
}

.icon-xiangmujine:before {
  content: "\e630";
}

.icon-jine:before {
  content: "\e78b";
}

.icon-jine1:before {
  content: "\e63a";
}

.icon-waimai:before {
  content: "\e618";
}

.icon-arrowupb:before {
  content: "\e6f6";
}

.icon-edit4:before {
  content: "\e6c5";
}

.icon-edit5:before {
  content: "\e84b";
}

.icon-edit6:before {
  content: "\e60b";
}

.icon-baseline-close-px:before {
  content: "\e615";
}

.icon-shuxing:before {
  content: "\e65b";
}

.icon-password:before {
  content: "\e67f";
}

.icon-jiantou1:before {
  content: "\e606";
}

.icon-user:before {
  content: "\e617";
}

.icon-user1:before {
  content: "\e622";
}

.icon-icon_sanjiaoxing:before {
  content: "\e6bb";
}

.icon-jiaoseguanli:before {
  content: "\e66c";
}

.icon-jiaoseshezhi:before {
  content: "\e634";
}

.icon-mendian:before {
  content: "\e60a";
}

.icon-dingdan:before {
  content: "\e67c";
}

.icon-huiyuan:before {
  content: "\e625";
}

.icon-mendian1:before {
  content: "\e628";
}

.icon-huiyuan1:before {
  content: "\e604";
}

.icon-shangpinfenlei:before {
  content: "\e62f";
}

.icon-shangpinliebiao:before {
  content: "\e61e";
}

.icon-leimupinleifenleileibie2:before {
  content: "\e7f8";
}

.icon-yuangongliebiao:before {
  content: "\e633";
}

.icon-gangweitubiao:before {
  content: "\e603";
}

.icon-printer:before {
  content: "\e67e";
}

.icon-cc-printer:before {
  content: "\e68f";
}

.icon-addressfill:before {
  content: "\e659";
}

.icon-fanhui:before {
  content: "\e601";
}

.icon-gengduo:before {
  content: "\e605";
}

.icon-arrow-bottom:before {
  content: "\e602";
}

.icon-top:before {
  content: "\e676";
}

.icon-edit:before {
  content: "\e649";
}

.icon-edit1:before {
  content: "\e938";
}

.icon-user02:before {
  content: "\e6fe";
}

.icon-clock:before {
  content: "\e65c";
}

.icon-plus:before {
  content: "\ea05";
}

.icon-lock3suo:before {
  content: "\e6b1";
}

.icon-delete:before {
  content: "\e655";
}

.icon-delete1:before {
  content: "\e63f";
}

.icon-search:before {
  content: "\e61d";
}

.icon-clock1:before {
  content: "\e624";
}

.icon-edit2:before {
  content: "\e627";
}

.icon-clock-circle-o:before {
  content: "\e968";
}

.icon-edit3:before {
  content: "\e717";
}

.icon-jian:before {
  content: "\e64e";
}

