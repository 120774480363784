.admin-statistics-page {
  padding: 15px; }
  .admin-statistics-page .data-card {
    display: flex;
    line-height: 2em;
    justify-content: space-between; }
    .admin-statistics-page .data-card .item {
      margin: 0 15px;
      padding: 15px;
      flex-grow: 1;
      border: 1px solid #ddd; }
      .admin-statistics-page .data-card .item .number {
        line-height: 3em;
        font-size: 20px;
        text-align: center; }
      .admin-statistics-page .data-card .item .trend {
        display: flex;
        justify-content: space-between; }
        .admin-statistics-page .data-card .item .trend i {
          margin-left: 12px; }
      .admin-statistics-page .data-card .item .daily {
        border-top: 1px solid #ddd; }
      .admin-statistics-page .data-card .item:nth-child(1) {
        margin-left: 0; }
      .admin-statistics-page .data-card .item:nth-last-child(1) {
        margin-right: 0; }
  .admin-statistics-page .data-content .rank {
    display: inline-block;
    width: 58%;
    margin-right: 2%; }
  .admin-statistics-page .data-content .message {
    display: inline-block;
    width: 40%;
    margin-top: 15px;
    padding: 15px;
    vertical-align: top;
    border: 1px solid #ddd; }
    .admin-statistics-page .data-content .message .title {
      font-weight: 600; }
    .admin-statistics-page .data-content .message ul {
      margin: 0; }
      .admin-statistics-page .data-content .message ul li {
        display: flex;
        height: 80px;
        padding: 8px 0; }
        .admin-statistics-page .data-content .message ul li img {
          width: 80px;
          height: 100%;
          background-color: #eee; }
        .admin-statistics-page .data-content .message ul li .theme {
          flex-grow: 1;
          padding: 0 12px; }
        .admin-statistics-page .data-content .message ul li .right {
          width: 90px;
          text-align: center; }
          .admin-statistics-page .data-content .message ul li .right button {
            color: #FFF !important;
            margin-top: 15px;
            border: none; }
            .admin-statistics-page .data-content .message ul li .right button.toRead {
              background-color: #3299F7; }
            .admin-statistics-page .data-content .message ul li .right button.read {
              background-color: #C1BFBF; }
        .admin-statistics-page .data-content .message ul li .type {
          color: #F39B4A; }
        .admin-statistics-page .data-content .message ul li .type.green {
          color: #54C0A0; }
  .admin-statistics-page .data-content .rank-container {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid #ddd; }
    .admin-statistics-page .data-content .rank-container .title {
      display: flex;
      justify-content: space-between;
      line-height: 36px; }
      .admin-statistics-page .data-content .rank-container .title .text {
        font-weight: 600; }
    .admin-statistics-page .data-content .rank-container .tabs {
      display: inline-block;
      margin: 0 15px;
      border: 1px solid #0E75F6;
      border-radius: 4px;
      overflow: hidden; }
      .admin-statistics-page .data-content .rank-container .tabs .tab {
        display: inline-block;
        padding: 0 15px;
        cursor: pointer; }
        .admin-statistics-page .data-content .rank-container .tabs .tab:not(:nth-last-child(1)) {
          border-right: 1px solid #0E75F6; }
        .admin-statistics-page .data-content .rank-container .tabs .tab.active {
          color: #FFF;
          background-color: #0E75F6; }
    .admin-statistics-page .data-content .rank-container .more {
      display: inline-block;
      width: 100px;
      padding: 0 20px;
      border-radius: 4px;
      border: 1px solid #ddd;
      text-align: center;
      cursor: pointer;
      color: #666; }
    .admin-statistics-page .data-content .rank-container table {
      width: 100%;
      margin-top: 15px;
      line-height: 2em; }
      .admin-statistics-page .data-content .rank-container table tr .no {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        color: #FFF;
        text-align: center;
        background-color: #324658; }
      .admin-statistics-page .data-content .rank-container table tr:nth-child(n + 7) {
        color: #666; }
        .admin-statistics-page .data-content .rank-container table tr:nth-child(n + 7) .no {
          color: #666;
          background-color: #F4F4F4; }
      .admin-statistics-page .data-content .rank-container table tr img {
        max-width: 80px; }

.admin-statistics-list-page .query .items {
  display: inline-block; }
  .admin-statistics-list-page .query .items .item {
    display: inline-block;
    min-width: 88px;
    padding: 0 15px;
    margin-left: -1px;
    line-height: 36px;
    color: #1684FC;
    border: 1px solid #1684FC;
    text-align: center;
    cursor: pointer; }
    .admin-statistics-list-page .query .items .item.active {
      color: #FFF;
      background-color: #1684FC; }
    .admin-statistics-list-page .query .items .item:nth-child(1) {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px; }
    .admin-statistics-list-page .query .items .item:nth-last-child(1) {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px; }

.admin-statistics-list-page .table-list td img {
  max-width: 80px;
  max-height: 60px; }
