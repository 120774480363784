.help-list {
  margin: 35px 0;
  padding: 0; }
  .help-list li {
    display: flex;
    width: 600px;
    padding: 12px 0;
    justify-content: space-between;
    align-items: center; }
    .help-list li div {
      flex-grow: 1; }
    .help-list li button {
      min-width: 48px;
      margin: 0 10px; }
